import Vue from 'vue';
import {getCheckout, getUpdateBillingInfo} from "@/services/chargebee";
import { useSnackbarStore } from "@/stores/theme/snackbar";

const initOptions = {
  site: 'tilaacloud-test',
  publishableKey: 'test__'
};

const _chargebee = window.Chargebee ? window.Chargebee.init(initOptions) : null;

const Plugin = {
  install(Vue) {
    Vue.prototype.$chargebee = _chargebee;

    Vue.prototype.$openServerlessContainersCheckout = function () {
      return new Promise((resolve, reject) => {
        this.$chargebee.openCheckout({
          hostedPage: function () {
            return getCheckout(process.env.VUE_APP_SERVERLESS_CONTAINERS_SUBSCRIPTION_PLAN_ID)
              .then(response => response)
              .catch(error => reject(error));
          },
          error: function (error) {
            reject(error);
          },
          success: () => {
            const snackbarStore = useSnackbarStore();
            snackbarStore.toggleSnackbarVisibility({
              message: `Your subscription to serverless containers has been created successfully! 🚀`,
              color: "success",
            });
            resolve('success');
          },
          close: function () {
            resolve(null);
          }
        });
      });
    };

    Vue.prototype.$openSubscriberBillingDetails = function () {
      this.$chargebee.openCheckout({
        hostedPage: function () {
          return getUpdateBillingInfo()
            .then(response => response)
            .catch(error => {
              throw error;
            });
        },
      });
    }
  }
};

Vue.use(Plugin);

export default Plugin;
